import { isFieldError } from "../utils";
import { Container } from "./styled";

const Input = ({
  type = "text",
  name,
  label,
  reqd,
  autocomplete = name,
  update,
  errors,
  visible = true,
}) => {
  return (
    <Container className={visible ? `` : `invisible`}>
      <label htmlFor={name}>
        {`${label}`} {reqd ? <span className="error">*</span> : null}
      </label>
      <input
        type={type === "text-secure" ? "text" : type}
        name={type === "text-secure" ? null : name}
        data-encrypted-name={type === "text-secure" ? name : null}
        id={name}
        autoComplete={autocomplete}
        onChange={(e) => update(name, e.target.value)}
        className={isFieldError(errors, name) ? `error` : ``}
      />
    </Container>
  );
};
export default Input;
