import { isFieldError } from "../utils";
import { Container } from "./styled";

const Textarea = ({
  name,
  label,
  reqd,
  autocomplete = name,
  update,
  errors,
  visible = true,
}) => {
  return (
    <Container className={visible ? `` : `invisible`}>
      <label htmlFor={name}>
        {`${label}`} {reqd ? <span className="error">*</span> : null}
      </label>
      <textarea
        name={name}
        id={name}
        autoComplete={autocomplete}
        onChange={(e) => update(name, e.target.value)}
        className={isFieldError(errors, name) ? `error` : ``}
      />
    </Container>
  );
};
export default Textarea;
