import { useState, useEffect } from "react";
import { isFieldError, createMarkup } from "../utils";
import { CheckboxContainer, Note } from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare, faCheckSquare } from "@fortawesome/pro-light-svg-icons";

const Radios = ({
  name,
  label,
  reqd,
  update,
  note,
  errors,
  uncheck = false,
}) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    //console.log("useEffect for checkbox called");

    if (uncheck) {
      setChecked(false);
    }
  }, [uncheck]);

  const handleClick = (name) => {
    setChecked(!checked);
    update(name, !checked);
  };

  return (
    <div>
      <CheckboxContainer className={isFieldError(errors, name) ? `error` : ``}>
        <button type="button" onClick={(e) => handleClick(name)}>
          <div>
            <FontAwesomeIcon
              icon={checked ? faCheckSquare : faSquare}
              size="lg"
            />
            <span>
              {" "}
              {`${label}`} {reqd ? <span className="error">*</span> : null}
            </span>
          </div>
        </button>
      </CheckboxContainer>
      {note && <Note dangerouslySetInnerHTML={createMarkup(note)} />}
    </div>
  );
};
export default Radios;
