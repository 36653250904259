import { useState, useEffect, Fragment } from "react";
import useFetch from "./useFetch";
import { apiUrl } from "./utils";
import Alert from "./Alert";
import { Table } from "./styled";

function fmtDate(secs) {
  const d = new Date(secs);
  const mo = d.getMonth() + 1;
  const day = d.getDate();
  const yr = d.getFullYear().toString().slice(-2);
  return `${mo}/${day}/${yr}`;
}

const Orders = ({ started, location }) => {
  const [url, setUrl] = useState(null);
  const [url2, setUrl2] = useState(null);
  const [selected, setSelected] = useState("");

  const { loading, data } = useFetch(url);
  const { loading: loading2, data: data2 } = useFetch(url2);

  const API_URL = apiUrl(location);

  useEffect(() => {
    //console.log("Order useEffect called for url");

    const newUrl = new URL(`${API_URL}/ordersInit`);
    setUrl(newUrl);
  }, []);

  const handleClick = (name) => {
    setSelected("");
    setUrl2(null);
    const newUrl = new URL(`${API_URL}/orders`);
    const params = {
      name,
    };
    newUrl.search = new URLSearchParams(params);
    setUrl2(newUrl);
    setSelected(name);
  };

  return (
    <div>
      {loading ? (
        <Alert data={{ type: "working", text: "" }} />
      ) : data.resp === 0 ? (
        <Alert data={{ type: "error", text: data.text }} />
      ) : (
        <Fragment>
          <Table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Order ID</th>
                <th>Expiration</th>
              </tr>
            </thead>
            <tbody>
              {data.keys.map((item) => {
                return (
                  <tr
                    id={item.name}
                    style={{
                      backgroundColor:
                        selected === item.name ? "lightblue" : "transparent",
                    }}
                  >
                    <td className="center">
                      {fmtDate(parseInt(item.name, 10))}
                    </td>
                    <td
                      className="center"
                      style={{ paddingTop: "12.5px", paddingBottom: "12.5px" }}
                    >
                      <button
                        type="button"
                        onClick={() => handleClick(item.name)}
                        style={{
                          border: "1px solid var(--border)",
                          backgroundColor: "#fff",
                          padding: "4.5px 5px 2.5px 5px",
                          fontSize: "0.9rem",
                          outline: "none",
                        }}
                      >
                        {item.name}
                      </button>
                    </td>
                    <td className="center">
                      {fmtDate(parseInt(item.expiration, 10) * 1000)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>

          {!loading2 && data2 && data2.resp === 1 && (
            <div style={{ marginTop: "25px" }}>
              {JSON.stringify(data2.receipt)}
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default Orders;
