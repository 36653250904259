import { Container } from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleNotch,
  faTimes,
  faCheck,
  faExclamationTriangle,
} from "@fortawesome/pro-light-svg-icons";

const Alert = ({ data }) => {
  return (
    <Container className={data.type}>
      {data.type === "working" ? (
        <FontAwesomeIcon icon={faCircleNotch} size="lg" spin />
      ) : data.type === "success" ? (
        <FontAwesomeIcon icon={faCheck} size="lg" />
      ) : data.type === "notice" ? (
        <FontAwesomeIcon icon={faExclamationTriangle} size="lg" />
      ) : (
        <FontAwesomeIcon icon={faTimes} size="lg" />
      )}
      {data.text && <span>{data.text}</span>}
    </Container>
  );
};

export default Alert;
