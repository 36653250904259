import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  :root {
    --working: #ccc;
    --success: #155724;
    --error: #721c24;
    --notice: #856404;
    --grey: #f3f3f3;
    --border: #ddd;
    --divider: #aeaeae;
    --blue: #333f48;
    --lightblue: rgba(51, 63, 72, 0.5);
    --copper: #e95741;
    --boxshadow: 0 1px 8px 0 rgba(0, 0, 0, 0.04);
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  #appsuite-root, div {
    margin: 0;
    padding: 0;
  }

  #appsuite-root {
    width: 100%;

    @media only screen and (max-width: 649px) {
      padding-left: 15px;
      padding-right: 15px
    }
  }

  a {
    text-decoration: none
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
`;

export const Form = styled.form`
  width: 100%;

  & > div {
    margin-top: 15px;
  }

  & > p {
    margin-top: 10px;
    font-size: 0.9rem;
  }
`;

export const FormRow1 = styled.div`
  width: 100%;

  & h4 {
    font-size: 1.45rem !important;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 0;
    color: var(--blue);
    border-bottom: 1px solid var(--border);
    padding-bottom: 7.5px;
  }
`;

export const FormRow2 = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;

  @media only screen and (max-width: 649px) {
    grid-template-columns: 1fr;
  }

  &.first-smaller {
    grid-template-columns: 0.4fr 1fr;
  }

  & div.empty {
    display: none;
  }
`;

export const FormRow3 = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 0.5fr 0.5fr;

  @media only screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }

  & > div:first-child {
    margin-right: 7.5px;

    @media only screen and (max-width: 767px) {
      margin-right: 0;
    }
  }

  & > div:nth-child(2) {
    margin-left: 7.5px;
    margin-right: 15px;

    @media only screen and (max-width: 767px) {
      margin-left: 0;
      margin-right: 0;
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }

  & div.empty {
    display: none;
  }
`;

export const FormMsgSubmit = styled.div`
  padding-top: 7.5px;
  width: 100%;
`;

export const Table = styled.table`
  margin-top: 25px;
  width: 100%;
  border-collapse: collapse;
  padding: 15px 0;
  font-size: 0.95rem;
  background-color: #fff;

  & th,
  & td {
    border: 1px solid var(--border);
  }

  & th {
    background-color: var(--grey);
    padding: 7.5px;
    vertical-align: middle;
    text-align: center;
  }

  & td {
    vertical-align: middle;
    padding: 5px 7.5px;
  }

  & th.left {
    text-align: left;
  }

  & th.qty {
    width: 10%;

    @media only screen and (max-width: 767px) {
      width: 12.5%;
    }
  }

  & th.total {
    width: 15%;
  }

  & th.right,
  & td.right {
    text-align: right;
  }

  & td.center {
    text-align: center;
  }

  & td .larger {
    font-size: 1rem;
  }

  & tfoot td {
    padding: 12.5px 7.5px 10px 7.5px;
    font-size: 1.05rem;
  }
`;
