import { useState, useEffect, useRef, Fragment } from "react";
import useFetch from "./useFetch";
import { apiUrl, setValToLS, getValFromLS, fmtLocation } from "./utils";
import Alert from "./Alert";
import Input from "./Input";
import Select from "./Select";
import Textarea from "./Textarea";
import Msg from "./Msg";
import Submit from "./Submit";
import { CartContainer, BtnsGrid } from "./order-styled";
import { Form, FormRow1, FormRow2, FormMsgSubmit } from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShoppingBag,
  faChevronCircleRight,
} from "@fortawesome/pro-light-svg-icons";

const Order = ({ started, location }) => {
  const [url, setUrl] = useState(null);
  const [selected, setSelected] = useState([]);
  const [names, setNames] = useState({});
  const [errors, setErrors] = useState([]);
  const [msg, setMsg] = useState({});

  const formElement = useRef(null);
  const { loading, data } = useFetch(url);

  const API_URL = apiUrl(location);

  useEffect(() => {
    //console.log("Order useEffect called for url");

    const newUrl = new URL(`${API_URL}/orderInit`);
    /*
      const params = {
        busId: businessObj.id,
      };
      newUrl.search = new URLSearchParams(params);
    */
    setUrl(newUrl);
  }, []);

  /*
  useEffect(() => {
    if (data && data.resp === 1) {
      console.log("Order data useEffect called");

      setValToLS("giftcards", JSON.stringify(data.giftcards), true);
    }
  }, [data]);
  */

  const btns = [
    {
      id: "50",
      name: "$50",
    },
    {
      id: "75",
      name: "$75",
    },
    {
      id: "100",
      name: "$100",
    },
    {
      id: "150",
      name: "$150",
    },
    {
      id: "200",
      name: "$200",
    },
    {
      id: "other",
      name: "Other",
    },
  ];

  const handleUpdate = (name, value) => {
    setNames((names) => ({ ...names, [name]: value ? value : "" }));
  };

  const handleClick = (amt) => {
    handleUpdate("amt", amt);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors([]);
    setMsg({
      type: "working",
      text: "",
    });

    const url = new URL(`${API_URL}/order`);
    let formData = new FormData();
    Object.entries(names).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append("location", location);
    formData.append("giftcards", JSON.stringify(data.giftcards));

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });
      const json = await response.json();
      if (json && json.resp === 1) {
        handleAdd(
          json.id,
          json.giftcard,
          json.name,
          json.amt,
          json.delivery,
          json.recipient,
          json.authnet
        );
        // json.bpay
      } else {
        setErrors(json.fields);
        setMsg({
          type: "error",
          text: json.text,
        });
      }
    } catch (error) {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      });
    }
  };

  const handleAdd = (id, giftcard, name, amt, delivery, recipient, authnet) => {
    let array = selected;
    array.push({
      id,
      giftcard,
      qty: 1,
      name,
      delivery,
      recipient,
      amt,
    });
    setSelected([...array]);
    setValToLS(`${location}order`, JSON.stringify(array), true);
    //setValToLS(`giftcardbpay`, JSON.stringify(bpay), true);
    setValToLS(`${location}authnet`, JSON.stringify(authnet), true);
    started(1);
  };

  return (
    <div>
      {loading ? (
        <Alert data={{ type: "working", text: "" }} />
      ) : data.resp === 0 ? (
        <Alert data={{ type: "error", text: data.text }} />
      ) : (
        <Fragment>
          <h3>Order e-gift cards to redeem at etta {fmtLocation(location)}.</h3>

          {getValFromLS(`${location}order`, true) &&
            JSON.parse(getValFromLS(`${location}order`, true)).length > 0 && (
              <CartContainer>
                <button type="button" onClick={() => started(1)}>
                  <div>
                    <FontAwesomeIcon icon={faShoppingBag} size="lg" />
                    <span>
                      {JSON.parse(getValFromLS(`${location}order`, true))
                        .length === 1
                        ? "1 Item"
                        : `${
                            JSON.parse(getValFromLS(`${location}order`, true))
                              .length
                          } Items`}
                    </span>
                  </div>
                </button>
              </CartContainer>
            )}

          <Form
            method="post"
            action="/"
            onSubmit={(e) => handleSubmit(e)}
            ref={formElement}
          >
            <BtnsGrid>
              {btns.map((btn) => {
                return (
                  <button
                    key={btn.id}
                    type="button"
                    onClick={() => handleClick(btn.id)}
                    className={btn.id === names.amt ? "selected" : ""}
                  >
                    {btn.name}
                  </button>
                );
              })}
            </BtnsGrid>

            {names.amt === "other" && (
              <FormRow2 style={{ marginBottom: "15px" }}>
                <Input
                  name="other"
                  label="Other Amount"
                  reqd={true}
                  autocomplete="off"
                  update={handleUpdate}
                  errors={errors}
                />

                <div className="empty" />
              </FormRow2>
            )}

            <FormRow1>
              <h4>Recipient Details</h4>
            </FormRow1>

            <FormRow2>
              <Select
                name="delivery"
                label="Delivery Date"
                reqd={true}
                autocomplete="off"
                data={[
                  { value: "", name: "--" },
                  {
                    value: "today",
                    name: "Today",
                  },
                  {
                    value: "tomorrow",
                    name: "Tomorrow",
                  },
                  {
                    value: "custom",
                    name: "Custom Date",
                  },
                ]}
                update={handleUpdate}
                errors={errors}
              />

              {names.delivery === "custom" ? (
                <Input
                  name="custom"
                  label="Custom Delivery Date"
                  reqd={true}
                  autocomplete="off"
                  update={handleUpdate}
                  errors={errors}
                />
              ) : (
                <div className="empty" />
              )}
            </FormRow2>

            <FormRow2>
              <Input
                name="fname"
                label="First Name"
                reqd={true}
                autocomplete="given-name"
                update={handleUpdate}
                errors={errors}
              />

              <Input
                name="lname"
                label="Last Name"
                reqd={true}
                autocomplete="family-name"
                update={handleUpdate}
                errors={errors}
              />
            </FormRow2>

            <FormRow2>
              <Input
                name="email"
                label="Email Address"
                reqd={true}
                autocomplete="email"
                update={handleUpdate}
                errors={errors}
              />

              <Input
                name="confirm"
                label="Confirm Email"
                reqd={true}
                autocomplete="off"
                update={handleUpdate}
                errors={errors}
              />
            </FormRow2>

            <FormRow1>
              <Textarea
                name="comments"
                label="Optional Gift Message"
                reqd={false}
                autocomplete="off"
                update={handleUpdate}
                errors={errors}
              />
            </FormRow1>

            <FormMsgSubmit>
              {msg.type && <Msg data={msg} />}
              {msg.type !== "working" && (
                <Submit
                  name="Continue to Payment"
                  icon={faChevronCircleRight}
                />
              )}
            </FormMsgSubmit>
          </Form>
        </Fragment>
      )}
    </div>
  );
};

export default Order;
