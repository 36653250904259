import styled from "styled-components";

export const CheckboxContainer = styled.div`
  width: 100%;

  & button {
    border: none;
    border-radius: 0;
    padding: 0;
    margin: 0;
    text-decoration: none;
    background: transparent;
    font-family: inherit;
    font-size: 0.95rem;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;

    &:hover,
    &:focus,
    &:active {
      color: inherit;
    }

    & > div {
      display: flex;
      align-items: center;

      & svg {
        font-size: 1.15rem;
      }

      & > span {
        padding-top: 1.475px;
        padding-left: 7.5px;
      }

      & > span .error {
        color: var(--error);
      }
    }
  }
`;

export const Note = styled.div`
  & > a {
    margin: 0;
    padding: 0;
    border: none;
    border-radius: 0;
    font-size: 0.9rem;
  }
`;
