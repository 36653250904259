import styled from "styled-components";

export const Container = styled.div`
  margin-bottom: 5px;

  & button {
    width: 100%;
    display: inline-block;
    border: none;
    border: 1px solid var(--blue);
    border-radius: 2px;
    padding: 7.5px 5px 6px 5px;
    margin: 0;
    text-decoration: none;
    background-color: var(--blue);
    color: #fff;
    font-family: inherit;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    transition: all 0.3s;

    &:hover {
      color: var(--copper);
    }

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;

      & > span {
        padding-right: 7.5px;
        text-transform: uppercase;
        letter-spacing: 0.5px;
      }
    }
  }
`;
