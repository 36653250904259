import styled from "styled-components";

export const Container = styled.div`
  &.invisible {
    visibility: hidden;
  }

  & label {
    display: block;
    cursor: pointer;
    font-size: 0.95rem;
    padding-bottom: 3.5px;
    margin-bottom: 0;
  }

  & label span.error {
    color: var(--error);
  }
`;

export const SelectContainer = styled.div`
  width: 100%;
  border: 1px solid var(--border);
  border-radius: 2px;
  padding: 4.5px 7.5px 2.5px 7.5px;
  padding: 3px 7.5px 3px 7.5px;
  box-shadow: var(--boxshadow);
  background-color: #fff;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
  display: grid;
  grid-template-areas: "select";
  align-items: center;

  & select {
    appearance: none;
    -webkit-appearance: none;
    width: 100%;
    background-color: #fff;
    border: none;
    padding: 2.75px 1em 2.25px 0;
    margin: 0;
    font-family: inherit;
    font-size: 16px;
    line-height: inherit;
    font-weight: 500;
    cursor: pointer;
    outline: none;
    grid-area: select;
  }

  &::after {
    content: "";
    width: 0.8em;
    height: 0.5em;
    background-color: #292b2e;
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  }

  &:after {
    grid-area: select;
    justify-self: end;
  }

  &:focus-within {
    border-color: var(--blue);
  }

  &.error {
    border-color: var(--error);
  }
`;
