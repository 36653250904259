import styled from "styled-components";

export const CartContainer = styled.div`
  text-align: right;
  padding-bottom: 0;

  & > button {
    display: inline-block;
    border: none;
    border-radius: 0;
    padding: 0;
    margin: 0;
    text-decoration: none;
    background: transparent;
    font-family: inherit;
    font-size: 1rem;
    color: var(--copper);
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;

    &:hover {
      color: var(--blue);
    }

    & > div {
      display: flex;
      align-items: center;

      & > span {
        padding-top: 2px;
        padding-left: 7.5px;
      }
    }
  }
`;

export const BtnsGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 15px;
  margin-bottom: 15px;

  & > button {
    display: inline-block;
    border: none;
    border: 1px solid var(--blue);
    border-radius: 4px;
    padding: 5.5px 5px 4px 5px;
    margin: 0;
    text-decoration: none;
    background: transparent;
    background-color: #fff;
    font-family: inherit;
    font-size: 1.1rem;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    transition: all 0.3s;

    &:hover,
    &.selected {
      background-color: var(--blue);
      color: #fff;
    }
  }
`;
