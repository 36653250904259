import { useState, useEffect, Fragment } from "react";
import { getValFromLS, setValToLS, fmtCurrency } from "./utils";
import Alert from "./Alert";
import { Table1, Item } from "./receipt-styled";
import { Table } from "./styled";

const Receipt = ({ started, id }) => {
  const [receipt, setReceipt] = useState({});

  useEffect(() => {
    //window.scrollTo(0, 0);
    if (id && getValFromLS(`receipt`, true)) {
      setReceipt(JSON.parse(getValFromLS(`receipt`, true)));
      setValToLS("receipt", null, true);
    } else {
      started(0);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      {!receipt.orderId ? (
        <Alert data={{ type: "working", text: "" }} />
      ) : (
        <Fragment>
          <h3>{receipt.hdg}</h3>

          <Table1>
            <thead>
              <tr>
                <th>Date</th>
                <th>Purchaser</th>
                <th>Email</th>
                <th>Phone</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="center">{receipt.placedAt}</td>
                <td className="center">{receipt.name}</td>
                <td className="center">{receipt.email}</td>
                <td className="center">{receipt.phone}</td>
              </tr>
            </tbody>
          </Table1>

          <p style={{ fontSize: "0.9rem" }}>
            E-gift card(s) will be delivered to the recipient’s email address on
            the date specified.
          </p>

          <Table>
            <thead>
              <tr>
                <th className="left">Item</th>
                <th className="total">Total</th>
              </tr>
            </thead>

            <tbody>
              {receipt.items.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <Item>
                        <div>
                          <div>
                            <span className="larger">{item.item}</span>
                            <ul>
                              <li>{`Delivery: ${item.delivery}`}</li>
                              <li>{`Recipient: ${item.name} (${item.email}) `}</li>
                            </ul>

                            {item.comments && <small>{item.comments}</small>}
                          </div>
                        </div>
                      </Item>
                    </td>
                    <td className="right">{item.price}</td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <td className="right ">TOTAL</td>
                <td className="right">
                  {fmtCurrency(receipt.totals.totalAmt / 100)}
                </td>
              </tr>

              <tr>
                <td className="right ">{receipt.payment}</td>
                <td className="right">
                  ({fmtCurrency(receipt.totals.totalAmt / 100)})
                </td>
              </tr>
            </tfoot>
          </Table>
        </Fragment>
      )}
    </div>
  );
};

export default Receipt;
