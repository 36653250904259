import styled from "styled-components";
import { Table } from "./styled";

export const Table1 = styled(Table)`
  margin-bottom: 7.5px;

  & th {
    width: 29%;
  }

  & th:first-child {
    width: 13%;
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7.5px;

  & > div {
    display: flex;
    align-items: center;

    & > img {
      width: 135px;
      border-radius: 10px;
    }

    & > div {
      margin-left: 15px;

      & > ul {
        margin: 0;
        padding: 0;
        margin-left: 30px;
        font-size: 0.9rem;

        & > li {
          margin: 0;
          padding: 0;
          line-height: 22px;
        }
      }

      & > small {
        font-style: italic;
        font-size: 0.8rem;
      }
    }
  }

  & > button {
    border: none;
    border-radius: 0;
    padding: 0;
    margin: 0;
    text-decoration: none;
    background: transparent;
    font-family: inherit;
    font-size: 1rem;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    color: var(--error);
  }
`;
