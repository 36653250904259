import React from "react";
import ReactDOM from "react-dom";
import { GlobalStyle } from "./styled";
import App from "./App";
import Orders from "./Orders";
import Alert from "./Alert";

/* uncomment for testing locally
window.appsuiteSettings = {
  id: "giftcard", // giftcard, orders (local only)
  location: "scottsdale", // culvercity, scottsdale
};
*/

const target = document.getElementById("appsuite-root");
if (target) {
  if (window.appsuiteSettings) {
    const { id, location } = window.appsuiteSettings;

    ReactDOM.render(
      <React.StrictMode>
        <GlobalStyle />
        {id === "giftcard" ? (
          <App location={location} />
        ) : id === "orders" ? (
          <Orders location={location} />
        ) : (
          <Alert data={{ type: "notice", text: "Page under development." }} />
        )}
      </React.StrictMode>,
      target
    );
  } else {
    ReactDOM.render(
      <React.StrictMode>
        <GlobalStyle />
        <Alert data={{ type: "error", text: "An error has occurred." }} />
      </React.StrictMode>,
      target
    );
  }
}
