import React from "react";
import { Container } from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Submit = ({ name, icon }) => {
  return (
    <Container>
      <button type="submit">
        <div>
          <span>{name}</span>
          {icon && <FontAwesomeIcon icon={icon} size="lg" />}
        </div>
      </button>
    </Container>
  );
};
export default Submit;
