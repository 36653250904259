import { useState, Fragment } from "react";
import Order from "./Order";
import Review from "./Review";
import Receipt from "./Receipt";

const App = ({ location }) => {
  const [started, setStarted] = useState(0);
  const [submitted, setSubmitted] = useState(0);

  const handleStarted = (status) => {
    setStarted(status);
  };

  const handleSubmitted = (id) => {
    setSubmitted(id);
  };

  return (
    <Fragment>
      {started && submitted ? (
        <Receipt started={handleStarted} id={submitted} />
      ) : started ? (
        <Review
          started={handleStarted}
          submitted={handleSubmitted}
          location={location}
        />
      ) : (
        <Order started={handleStarted} location={location} />
      )}
    </Fragment>
  );
};

export default App;
