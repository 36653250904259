export const AUTHNET_SCRIPT = "https://js.authorize.net/v1/Accept.js";
export const RECAPTCHA_KEY = "6LeHt3saAAAAAG4_BZwFIC0zEzyQpbaleLWtO6Rj";

export function apiUrl(location) {
  return location === "scottsdale"
    ? "https://ettascottsdale-api.qnm.workers.dev"
    : "https://ettaculvercity-api.qnm.workers.dev";
}

/* API
  prod: https://ettaculvercity-api.qnm.workers.dev
  prod: https://ettascottsdale-api.qnm.workers.dev
  dev:  http://127.0.0.1:8787
*/

/* Authnet
  export const AUTHNET_SCRIPT = "https://js.authorize.net/v1/Accept.js";
  export const AUTHNET_SCRIPT = "https://jstest.authorize.net/v1/Accept.js";
  export const AUTHNET_LOGINID = "34fhtk8Be8C7";
  export const AUTHNET_PUBKEY =
    "44S79RZmJ3jf7teah5u8wp95WCcRHX2rJ3S6a48Hx3t6w6jarbaRQFnGEaLVcLd6";
*/

/* Bpay
  export const BPAY_SCRIPT = "https://secure.bluepay.com/v3/bluepay.js";
*/

export function isFieldError(array, val) {
  return array.includes(val);
}

export function createMarkup(html) {
  return { __html: html };
}

export function fmtLocation(location) {
  return location === "scottsdale" ? "Scottsdale" : "Culver City";
}

const isBrowser = () => typeof window !== "undefined";

export const getValFromLS = (key, useSessionStorage = false) => {
  if (useSessionStorage) {
    return isBrowser() && window.sessionStorage.getItem(key)
      ? window.sessionStorage.getItem(key)
      : undefined;
  } else {
    return isBrowser() && window.localStorage.getItem(key)
      ? window.localStorage.getItem(key)
      : undefined;
  }
};

export const setValToLS = (key, val, useSessionStorage = false) => {
  if (isBrowser() && val) {
    useSessionStorage
      ? window.sessionStorage.setItem(key, val)
      : window.localStorage.setItem(key, val);
  } else {
    useSessionStorage
      ? window.sessionStorage.removeItem(key)
      : window.localStorage.removeItem(key);
  }
  return true;
};

export function fmtCurrency(int) {
  const amt = int;
  return `${amt.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  })}`;
}

export function getVal(array, value, key) {
  let val = "";
  array.forEach((item) => {
    if (item.value === value) {
      val = item[key];
    }
  });
  return val;
}
