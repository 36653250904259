import { useState, useEffect, useRef, createRef, Fragment } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Script from "react-load-script";
import {
  apiUrl,
  AUTHNET_SCRIPT,
  RECAPTCHA_KEY,
  getValFromLS,
  setValToLS,
  fmtCurrency,
} from "./utils";
import Alert from "./Alert";
import Checkbox from "./Checkbox";
import Input from "./Input";
import Msg from "./Msg";
import Submit from "./Submit";
import { BackContainer, Item, ReCAPTCHACheckbox } from "./review-styled";
import {
  Table,
  Form,
  FormRow1,
  FormRow2,
  FormRow3,
  FormMsgSubmit,
} from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faTrashAlt,
  faChevronCircleRight,
} from "@fortawesome/pro-light-svg-icons";

const Review = ({ started, submitted, location }) => {
  const [items, setItems] = useState([]);
  const [totals, setTotals] = useState({
    subtotal: 0,
    tax: 0,
    tip: 0,
    total: 0,
  });
  const [names, setNames] = useState({});
  const [errors, setErrors] = useState([]);
  const [msg, setMsg] = useState({});

  const formElement = useRef(null);
  const recaptchaRef = createRef();

  const API_URL = apiUrl(location);

  useEffect(() => {
    //window.scrollTo(0, 0);
    if (
      getValFromLS(`${location}order`, true) &&
      getValFromLS(`${location}authnet`, true)
    ) {
      const array = JSON.parse(getValFromLS(`${location}order`, true));
      const authnet = JSON.parse(getValFromLS(`${location}authnet`, true));
      let total = 0;
      array.forEach((item) => {
        const subtotal = item.qty * item.amt;
        total = total + subtotal;
      });
      const subtotal = total;
      const tax = 0;
      const tip = 0;
      total = subtotal + tax + tip;
      setTotals({
        subtotal,
        tax,
        tip,
        total,
      });
      setItems(array);
      setNames({
        loginid: authnet.loginid,
        pubkey: authnet.pubkey,
      });
    } else {
      started(0);
    }
    // eslint-disable-next-line
  }, []);

  const handleRemove = (index) => {
    let array = items;
    array.splice(index, 1);
    let total = 0;
    array.forEach((item) => {
      const subtotal = item.qty * item.amt;
      total = total + subtotal;
    });
    const subtotal = total;
    const tax = 0;
    //const tip = names.gratuity ? Number(names.gratuity) * subtotal : 0;
    const tip = 0;
    total = subtotal + tax + tip;
    setTotals({
      subtotal,
      tax,
      tip,
      total,
    });
    setItems([...array]);
    setValToLS(`${location}order`, JSON.stringify(array), true);
  };

  const handleUpdate = (name, value) => {
    if (name === "same") {
      const fname = items[0].recipient.fname || "";
      const lname = items[0].recipient.lname || "";
      const email = items[0].recipient.email || "";
      formElement.current.fname.value = fname;
      formElement.current.lname.value = lname;
      formElement.current.email.value = email;
      setNames((names) => ({
        ...names,
        fname,
        lname,
        email,
        same: !names["same"],
      }));
    } else if (name === "checkbox") {
      setNames((names) => ({ ...names, [name]: !names.checkbox }));
      recaptchaRef.current.reset();
      recaptchaRef.current.execute();
    } else {
      setNames((names) => ({ ...names, [name]: value ? value : "" }));
    }
  };

  const handleChange = (value) => {
    if (value) {
      handleUpdate("recaptcha", value);
    } else {
      setNames((names) => ({ ...names, checkbox: false, recaptcha: "" }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors([]);
    setMsg({
      type: "working",
      text: "",
    });

    if (!names.ccard) {
      setErrors(["ccard"]);
      setMsg({
        type: "error",
        text: "Please enter a valid credit card number.",
      });
      return false;
    } else if (!names.expdate) {
      setErrors(["expdate"]);
      setMsg({
        type: "error",
        text: "Please enter a valid expiration date.",
      });
      return false;
    } else if (names.expdate.trim().replace(/\//g, "").length !== 4) {
      setErrors(["expdate"]);
      setMsg({
        type: "error",
        text:
          "Please enter exactly 4 numbers (mmyy) only for credit card expiration date.",
      });
      return false;
    } else if (!names.cvv) {
      setErrors(["cvv"]);
      setMsg({
        type: "error",
        text: "Please enter a valid credit card CVV code.",
      });
      return false;
    }

    const url = new URL(`${API_URL}/review`);
    let data = new FormData();
    Object.entries(names).forEach(([key, value]) => {
      if (
        key === "ccard" ||
        key === "expdate" ||
        key === "cvv" ||
        key === "loginid" ||
        key === "pubkey"
      ) {
      } else {
        data.append(key, value);
      }
    });
    data.append("items", getValFromLS(`${location}order`, true));
    data.append("totalAmt", totals.total || 0);
    data.append("tipAmt", totals.tip || 0);
    data.append("taxAmt", totals.tax || 0);

    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
      });
      const json = await response.json();
      if (json && json.resp === 1) {
        const authnetData = {
          apiLoginID: names.loginid,
          clientKey: names.pubkey,
        };
        const authnetCard = {
          cardNumber: names.ccard.replace(/-|\s/g, ""),
          month: names.expdate.substring(0, 2),
          year: names.expdate.substring(2, 4),
          cardCode: names.cvv,
          fullName: `${names.fname} ${names.lname}`,
        };
        window.Accept.dispatchData(
          { authData: authnetData, cardData: authnetCard },
          handleResponse
        );
      } else {
        setNames((names) => ({ ...names, checkbox: false, recaptcha: "" }));
        setErrors(json.fields);
        setMsg({
          type: "error",
          text: json.text,
        });
      }
    } catch (error) {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      });
    }
  };

  const handleResponse = (response) => {
    if (response.messages.resultCode === "Error") {
      //console.log(response.messages.message);
      setMsg({
        type: "error",
        text: `An error has occurred, (${response.messages.message[0].code}) ${response.messages.message[0].text}.`,
      });
    } else {
      //console.log(response.opaqueData);
      handleSuccess(response.opaqueData);
    }
  };

  const handleSuccess = async (opaqueData) => {
    const url = new URL(`${API_URL}/submit`);
    let data = new FormData();
    Object.entries(names).forEach(([key, value]) => {
      if (
        key === "ccard" ||
        key === "expdate" ||
        key === "cvv" ||
        key === "loginid" ||
        key === "pubkey"
      ) {
      } else {
        data.append(key, value);
      }
    });
    data.append("items", getValFromLS(`${location}order`, true) || "");
    data.append("totalAmt", totals.total || 0);
    data.append("tipAmt", totals.tip || 0);
    data.append("taxAmt", totals.tax || 0);
    data.append("opaqueData", JSON.stringify(opaqueData) || "");

    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
      });
      const json = await response.json();
      if (json && json.resp === 1) {
        setValToLS(`${location}order`, null, true);
        setValToLS(`${location}authnet`, null, true);
        setValToLS("receipt", JSON.stringify(json.receipt), true);
        setMsg({});
        submitted(json.receipt.orderId);
      } else {
        //recaptchaRef.current.reset();
        setMsg({
          type: "error",
          text: json.text,
        });
      }
    } catch (error) {
      //recaptchaRef.current.reset();
      setMsg({
        type: "error",
        text: "An error has occurred.",
      });
    }
  };

  const handleScriptCreate = () => {
    handleUpdate("scriptLoaded", false);
  };

  const handleScriptError = () => {
    setMsg({
      type: "error",
      text: "An error occurred loading the payment gateway.",
    });
  };

  const handleScriptLoad = () => {
    handleUpdate("scriptLoaded", true);
  };

  return (
    <div>
      <Fragment>
        <h3>
          Please review your order and enter your billing information below to
          submit your order.
        </h3>

        <BackContainer>
          <button type="button" onClick={() => started(0)}>
            <div>
              <FontAwesomeIcon icon={faChevronLeft} size="lg" />
              <span>Back to Order</span>
            </div>
          </button>
        </BackContainer>

        {items.length === 0 ? (
          <div style={{ marginTop: "20px" }}>
            <Alert
              data={{
                type: "error",
                text: "There are no items in your order.",
              }}
            />
          </div>
        ) : (
          <Fragment>
            <Table>
              <thead>
                <tr>
                  <th className="qty">Qty</th>
                  <th className="left">Item</th>
                  <th className="total">Total</th>
                </tr>
              </thead>

              <tbody>
                {items.map((item, index) => {
                  const subtotal = item.qty * item.amt;
                  return (
                    <tr key={index}>
                      <td className="center">
                        <span className="larger">{item.qty}</span>
                      </td>
                      <td>
                        <Item>
                          <div>
                            <img src={item.giftcard.img} alt="Gift Card" />
                            <div>
                              <span className="larger">{item.name}</span>
                              <ul>
                                <li>{`Delivery: ${item.delivery}`}</li>
                                <li>{`Recipient: ${item.recipient.fname} ${item.recipient.lname} (${item.recipient.email}) `}</li>
                              </ul>

                              {item.recipient.comments && (
                                <small>{item.recipient.comments}</small>
                              )}
                            </div>
                          </div>

                          <button
                            type="button"
                            onClick={() => handleRemove(index)}
                          >
                            <FontAwesomeIcon icon={faTrashAlt} size="lg" />
                          </button>
                        </Item>
                      </td>
                      <td className="right">{fmtCurrency(subtotal / 100)}</td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="2" className="right orderHeading">
                    TOTAL
                  </td>
                  <td className="right">{fmtCurrency(totals.total / 100)}</td>
                </tr>
              </tfoot>
            </Table>

            <Script
              url={AUTHNET_SCRIPT}
              onCreate={handleScriptCreate}
              onError={handleScriptError}
              onLoad={handleScriptLoad}
            />

            <Form
              method="post"
              action="/"
              onSubmit={(e) => handleSubmit(e)}
              ref={formElement}
            >
              <p>
                E-gift cards will be emailed to the recipient on the date
                specified.
              </p>

              <FormRow1>
                <h4>Billing Information</h4>
              </FormRow1>

              {!names.same && (
                <FormRow1>
                  <Checkbox
                    name="same"
                    label="Check to use card recipient as the billing information"
                    reqd={false}
                    autocomplete="off"
                    update={handleUpdate}
                    note=""
                    errors={errors}
                  />
                </FormRow1>
              )}

              <FormRow2>
                <Input
                  name="fname"
                  label="First Name"
                  reqd={true}
                  autocomplete="given-name"
                  update={handleUpdate}
                  errors={errors}
                />

                <Input
                  name="lname"
                  label="Last Name"
                  reqd={true}
                  autocomplete="family-name"
                  update={handleUpdate}
                  errors={errors}
                />
              </FormRow2>

              <FormRow2>
                <Input
                  name="email"
                  label="Email Address"
                  reqd={true}
                  autocomplete="email"
                  update={handleUpdate}
                  errors={errors}
                />

                <Input
                  name="phone"
                  label="Phone"
                  reqd={true}
                  autocomplete="tel"
                  update={handleUpdate}
                  errors={errors}
                />
              </FormRow2>

              <FormRow3>
                <Input
                  type="text-secure"
                  name="ccard"
                  label="Credit Card Number"
                  reqd={true}
                  autocomplete="cc-number"
                  update={handleUpdate}
                  errors={errors}
                />

                <Input
                  type="text-secure"
                  name="expdate"
                  label="Exp. Date (mmyy)"
                  reqd={true}
                  autocomplete="cc-exp"
                  update={handleUpdate}
                  errors={errors}
                />

                <Input
                  type="text-secure"
                  name="cvv"
                  label="CVV/CID"
                  reqd={true}
                  autocomplete="cc-csc"
                  update={handleUpdate}
                  errors={errors}
                />
              </FormRow3>

              <ReCAPTCHACheckbox className={names.checkbox ? "hidden" : ""}>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey={RECAPTCHA_KEY}
                  onChange={handleChange}
                  badge="inline"
                />

                <Checkbox
                  name="checkbox"
                  label="Check to verify reCAPTCHA"
                  reqd={true}
                  autocomplete="off"
                  update={handleUpdate}
                  note=""
                  errors={errors}
                  uncheck={!names.checkbox ? true : false}
                />
              </ReCAPTCHACheckbox>

              <FormMsgSubmit>
                {msg.type && <Msg data={msg} />}
                {msg.type !== "working" && (
                  <Submit
                    name="Submit Order & Payment"
                    icon={faChevronCircleRight}
                  />
                )}
              </FormMsgSubmit>
            </Form>
          </Fragment>
        )}
      </Fragment>
    </div>
  );
};

export default Review;
