import styled from "styled-components";

export const BackContainer = styled.div`
  width: 100%;

  & > button {
    display: inline-block;
    border: none;
    border: 1px solid var(--blue);
    border-radius: 4px;
    padding: 5.5px 12.5px 5px 12.5px;
    margin: 0;
    text-decoration: none;
    background: transparent;
    background-color: #fff;
    font-family: inherit;
    font-size: 0.9rem;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    transition: all 0.3s;

    &:hover {
      background-color: var(--blue);
      color: #fff;
    }

    & > div {
      display: flex;
      align-items: center;

      & > span {
        padding-left: 7.5px;
      }
    }
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7.5px;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  & > div {
    display: flex;
    align-items: center;

    @media only screen and (max-width: 767px) {
      flex-direction: column;
    }

    & > img {
      width: 150px;
      border-radius: 10px;
      border: 1px solid var(--lightblue);

      @media only screen and (max-width: 767px) {
        display: none;
      }
    }

    & > div {
      margin-left: 15px;

      & > ul {
        margin: 0;
        padding: 0;
        margin-left: 30px;
        font-size: 0.9rem;

        & > li {
          margin: 0;
          padding: 0;
          line-height: 22px;
        }
      }

      & > small {
        font-style: italic;
        font-size: 0.8rem;
      }
    }
  }

  & > button {
    border: none;
    border-radius: 0;
    padding: 0;
    margin: 0;
    text-decoration: none;
    background: transparent;
    font-family: inherit;
    font-size: 1rem;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    color: var(--error);

    @media only screen and (max-width: 767px) {
      margin-top: 15px;
    }
  }
`;

export const ReCAPTCHACheckbox = styled.div`
  width: 100%;
  padding-top: 15px;
  display: flex;
  flex-direction: column;

  &.hidden {
    display: none;
  }

  & > *:last-child {
    padding-top: 10px;
  }
`;
